import React, { useState } from "react";
import styled from "styled-components";
import { ApolloProvider } from "react-apollo";
import { client } from "./apollo/client";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import GlobalPage from "./pages/GlobalPage";
import TokenPage from "./pages/TokenPage";
import PairPage from "./pages/PairPage";
import { useGlobalData, useGlobalChartData } from "./contexts/GlobalData";
import { isAddress } from "./utils";
import AccountPage from "./pages/AccountPage";
import AllTokensPage from "./pages/AllTokensPage";
import AllPairsPage from "./pages/AllPairsPage";
import PinnedData from "./components/PinnedData";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";
import AccountLookup from "./pages/AccountLookup";
import LocalLoader from "./components/LocalLoader";
import { useLatestBlocks } from "./contexts/Application";
import { PAIR_BLACKLIST, TOKEN_BLACKLIST } from "./constants";

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
`;

// const ContentWrapper = styled.div`
//   position: relative;
//   display: grid;
//   grid-template-columns: ${({ open }) =>
//     open ? "220px 1fr 200px" : "220px 1fr 64px"};

//   @media screen and (max-width: 1400px) {
//     grid-template-columns: 220px 1fr;
//   }

//   @media screen and (max-width: 1080px) {
//     grid-template-columns: 1fr;
//     max-width: 100vw;
//     overflow: hidden;
//     grid-gap: 0;
//   }
// `;
const ContentWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${({ open }) =>
    open ? "1fr 200px" : "1fr 64px"};

  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width: 100vw;
    overflow: hidden;
    grid-gap: 0;
  }
`;

const Right = styled.div`
  position: absolute;
  right: 0;
  bottom: 0rem;
  z-index: 100000;
  width: ${({ open }) => (open ? "220px" : "64px")};
  height: ${({ open }) => (open ? "fit-content" : "64px")};
  overflow: auto;
  background: linear-gradient(193.68deg,#1E0038 0.68%,#000000 100.48%);
  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

const Center = styled.div`
  position: relative;

  height: 100%;
  z-index: 9999;
  transition: width 0.25s ease;
  /* background-color: ${({ theme }) => theme.onlyLight}; */
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const BG1 = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: -1000;
  background: #16072b;
`;

const BG2 = styled.div`
  position: absolute;
  height: 100%;
  z-index: -300;
  width: 100%;
  background: url(https://swap.tomb.com/static/media/sky.2b2b3d46.svg);
  background-repeat: no-repeat;
  background-size: 100vw;
`;

const BG3 = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  background: url(https://swap.tomb.com/static/media/cemetry.2cb1c758.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 50vw;
  z-index: -100;
`;
const BG4 = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 50vw;
  z-index: -100;
  background: url(https://swap.tomb.com/static/media/bg-stars.9f79ec0b.svg);
`;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
`

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  return (
    <Wrapper>
      <BG1 />
      <BG2 />
      <BG3 />
      <BG4 />
      <SideNav />
      <ContentWrapper open={savedOpen}>
        {/* <BackgroundContainer> */}
        {/* </BackgroundContainer> */}
        <Center id="center">
          {children}
          <Footer />
        </Center>
        <Right open={savedOpen}>
          <PinnedData open={savedOpen} setSavedOpen={setSavedOpen} />
        </Right>
      </ContentWrapper>
    </Wrapper>
  );
};

const BLOCK_DIFFERENCE_THRESHOLD = 500;

function App() {
  const [savedOpen, setSavedOpen] = useState(false);

  const globalData = useGlobalData();
  const globalChartData = useGlobalChartData();
  const [latestBlock, headBlock] = useLatestBlocks();

  // show warning
  const showWarning =
    headBlock && latestBlock
      ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD
      : false;

  return (
    <ApolloProvider client={client}>
      <AppWrapper>
        {showWarning && (
          <WarningWrapper>
            <WarningBanner>
              {`Warning: The data on this site has only synced to Fantom block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
            </WarningBanner>
          </WarningWrapper>
        )}
        {globalData &&
          Object.keys(globalData).length > 0 &&
          globalChartData &&
          Object.keys(globalChartData).length > 0 ? (
          <BrowserRouter>
            <Switch>
              <Route
                exacts
                strict
                path="/token/:tokenAddress"
                render={({ match }) => {
                  if (
                    isAddress(match.params.tokenAddress.toLowerCase()) &&
                    !Object.keys(TOKEN_BLACKLIST).includes(
                      match.params.tokenAddress.toLowerCase()
                    )
                  ) {
                    return (
                      <LayoutWrapper
                        savedOpen={savedOpen}
                        setSavedOpen={setSavedOpen}
                      >
                        <TokenPage
                          address={match.params.tokenAddress.toLowerCase()}
                        />
                      </LayoutWrapper>
                    );
                  } else {
                    return <Redirect to="/home" />;
                  }
                }}
              />
              <Route
                exacts
                strict
                path="/pair/:pairAddress"
                render={({ match }) => {
                  if (
                    isAddress(match.params.pairAddress.toLowerCase()) &&
                    !Object.keys(PAIR_BLACKLIST).includes(
                      match.params.pairAddress.toLowerCase()
                    )
                  ) {
                    return (
                      <LayoutWrapper
                        savedOpen={savedOpen}
                        setSavedOpen={setSavedOpen}
                      >
                        <PairPage
                          pairAddress={match.params.pairAddress.toLowerCase()}
                        />
                      </LayoutWrapper>
                    );
                  } else {
                    return <Redirect to="/home" />;
                  }
                }}
              />
              <Route
                exacts
                strict
                path="/account/:accountAddress"
                render={({ match }) => {
                  if (isAddress(match.params.accountAddress.toLowerCase())) {
                    return (
                      <LayoutWrapper
                        savedOpen={savedOpen}
                        setSavedOpen={setSavedOpen}
                      >
                        <AccountPage
                          account={match.params.accountAddress.toLowerCase()}
                        />
                      </LayoutWrapper>
                    );
                  } else {
                    return <Redirect to="/home" />;
                  }
                }}
              />

              <Route path="/home">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <GlobalPage />
                </LayoutWrapper>
              </Route>

              <Route path="/tokens">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <AllTokensPage />
                </LayoutWrapper>
              </Route>

              <Route path="/pairs">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <AllPairsPage />
                </LayoutWrapper>
              </Route>

              <Route path="/accounts">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <AccountLookup />
                </LayoutWrapper>
              </Route>

              <Redirect to="/home" />
            </Switch>
          </BrowserRouter>
        ) : (
          <LocalLoader fill="true" />
        )}
      </AppWrapper>
    </ApolloProvider>
  );
}

export default App;
