import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'
import EthereumLogo from '../../assets/eth.png'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  /* background-color: white; */
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address, symbol, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
          🤔
        </span>
      </Inline>
    )
  }

  // hard coded fixes for trust wallet api issues
  if (address?.toLowerCase() === '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb') {
    address = '0x42456d7084eacf4083f1140d3229471bba2949a8'
  }

  if (address?.toLowerCase() === '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f') {
    address = '0xc011a72400e58ecd99ee497cf89e3775d4bd732f'
  }

  if (address?.toLowerCase() === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={EthereumLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  // const path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/fantom/assets/${isAddress(address)}/logo.svg`

  symbol = symbol?.toLowerCase();

  let path;
  switch (address?.toLowerCase()) {
    //tomb
    case '0x6c021ae822bea943b2e66552bde1d2696a53fbb7':
      path = "https://swap.tomb.com/images/tokens/TOMB.svg";
      break;
    //miMATIC
    case '0xfb98b335551a418cd0737375a2ea0ded62ea213b':
      path = "https://swap.tomb.com/images/tokens/MAI.svg";
      break;
    //TSHARE
    case '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37':
      path = "https://swap.tomb.com/images/tokens/TSHARE.svg";
      break;
    //TBOND
    case '0x24248cd1747348bdc971a5395f4b3cd7fee94ea0':
      path = "https://swap.tomb.com/images/tokens/TBOND.svg";
      break;
    //WFTM
    case '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83':
      path = "https://swap.tomb.com/images/tokens/WFTM.svg";
      break;
    //BASED
      case '0x8d7d3409881b51466b483b11ea1b8a03cded89ae':
      path = "https://swap.tomb.com/images/tokens/BASED.svg";
      break;
    //BTC
      case '0x321162cd933e2be498cd2267a90534a804051b11':
      path = "https://swap.tomb.com/images/tokens/BTC.svg";
      break;
    //WETH
      case '0x74b23882a30290451a17c44f4f05243b6b58c76d':
      path = "https://swap.tomb.com/images/tokens/WETH.svg";
      break;
    //USDC
      case '0x04068da6c83afcfa0e13ba15a6696662335d5b75':
      path = "https://swap.tomb.com/images/tokens/USDC.svg";
      break;
    //MIM
      case '0x82f0b8b456c1a451378467398982d4834b6829c1':
      path = "https://swap.tomb.com/images/tokens/MIM.svg";
      break;
    //FUSDT
      case '0x049d68029688eabf473097a2fc38ef61633a3c7a':
      path = "https://swap.tomb.com/images/tokens/FRAPPEDUSDT.svg";
      break;
    //BSHARE
      case '0x49c290ff692149a4e16611c694fded42c954ab7a':
      path = "https://swap.tomb.com/images/tokens/BSHARE.svg";
      break;
    //FUSD
    case '0xad84341756bf337f5a0164515b1f6f993d194e1f':
      path = "https://swap.tomb.com/images/tokens/FUSD.svg";
      break;
    //TREEB
    case '0xc60d7067dfbc6f2caf30523a064f416a5af52963':
      path = "https://swap.tomb.com/images/tokens/TREEB.svg";
      break;
    //ZOO
    case '0x09e145a1d53c0045f41aeef25d8ff982ae74dd56':
      path = "https://swap.tomb.com/images/tokens/ZOO.svg";
      break;
    //SCREAM
    case '0xe0654c8e6fd4d733349ac7e09f6f23da256bf475':
      path = "https://swap.tomb.com/images/tokens/SCREAM.svg";
      break;
    //AVAX
    case '0x511d35c52a3c244e7b8bd92c0c297755fbd89212':
      path = "https://swap.tomb.com/images/tokens/AVAX.svg";
      break;
    //DAI
    case '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e':
      path = "https://swap.tomb.com/images/tokens/DAI.svg";
      break;
    //BNB
    case '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454':
      path = "https://swap.tomb.com/images/tokens/BNB.svg";
      break;
    //CRV
    case '0x1e4f97b9f9f913c46f1632781732927b9019c68b':
      path = "https://swap.tomb.com/images/tokens/CRV.svg";
      break;
    //LINK
    case '0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8':
      path = "https://swap.tomb.com/images/tokens/LINK.svg";
      break;
    
    //LIF3
    case '0xbf60e7414ef09026733c1e7de72e7393888c64da':
      path = "https://swap.tomb.com/images/tokens/LIF3.svg";
      break;
    //LSHARE
    case '0xcbe0ca46399af916784cadf5bcc3aed2052d6c45':
      path = "https://swap.tomb.com/images/tokens/LSHARE.svg";
      break;
    //L3USD
    case '0x5f0456f728e2d59028b4f5b8ad8c604100724c6a':
      path = "https://swap.tomb.com/images/tokens/L3USD.svg";
      break;

    default:
      path =`https://swap.tomb.com/images/tokens/tombswap-light.svg`
  }

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
