import { transparentize } from "polished"
import React from "react"
import { Disc, List, PieChart, TrendingUp } from "react-feather"
import { withRouter } from "react-router-dom"
import { useMedia } from "react-use"
import styled from "styled-components"
import { useSessionStart } from "../../contexts/Application"
import { useDarkModeManager } from "../../contexts/LocalStorage"
import { TYPE } from "../../Theme"
import { BasicLink } from "../Link"
import Search from "../Search"
import Title from "../Title"

const Wrapper = styled.div`
  height: 5rem;
  /* height: ${({ isMobile }) => (isMobile ? "initial" : "100vh")}; */
  /* background-color: ${({ theme }) => transparentize(0.4, theme.bg1)}; */
  /* color: ${({ theme }) => theme.text1}; */
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  /* position: sticky; */
  top: 0px;
  box-sizing: border-box;
  /* background-color: #1b1c22; */
  /* background: linear-gradient(193.68deg, #1b1c22 0.68%, #000000 100.48%); */
  /* background: linear-gradient(193.68deg, rgba(30, 0, 56, 0.7) 0.68%, rgba(0,0,0,0.7) 100.48%); */
  /* background: linear-gradient(193.68deg, #1E0038 75%, #16002C 100%); */

  background: linear-gradient(rgb(54, 39, 69) 16.15%, rgba(54, 39, 69, 0) 100%);

  width: 100%;
  color: ${({ theme }) => theme.bg2};

  @media screen and (max-width: 1080px) {
    height: unset;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    position: relative;
  }

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  text-shadow: ${({ activeText }) =>
    activeText ? "0 -2px 8px rgb(82 194 230 / 50%)" : "none"};
  color: ${({ activeText }) => (activeText ? "rgb(109, 255, 191)" : "white")};
  display: flex;
  font-family: Sans-Serif;
  font-weight: 900;
  letter-spacing: 0.5px;
  align-items: center;
  :hover {
    opacity: 1;
  }
  :before {
    --tw-ring-color: rgb(59 130 246 / 0.5);
  }
  :after {
    --tw-ring-color: rgb(59 130 246 / 0.5);
  }
`

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`

const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const HeaderText = styled.div`
  margin-right: 0.75rem;
  font-size: 0.825rem;
  font-weight: 500;
  display: inline-box;
  display: -webkit-inline-box;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  a {
    color: ${({ theme }) => theme.white};
  }
`

const Polling = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  padding: 1rem;
  color: white;
  opacity: 0.4;
  transition: opacity 0.25s ease;
  :hover {
    opacity: 1;
  }
`
const PollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  margin-top: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.green1};
`

const Navbar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Spacer = styled.div`
  width: 160px;
`

const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  & > *:not(:last-child) {
    margin-right: 2rem;
  }
`

function SideNav({ history }) {
  const below1080 = useMedia("(max-width: 1080px)")

  const below1180 = useMedia("(max-width: 1180px)")

  const seconds = useSessionStart()

  const [isDark, toggleDarkMode] = useDarkModeManager()

  return (
    <Wrapper isMobile={below1080}>
      {!below1080 ? (
        <DesktopWrapper>
          <Navbar>
            <Title />
            {!below1080 && (
              <>
                <NavLinks>
                  <BasicLink to="/home">
                    <Option
                      activeText={
                        history.location.pathname === "/home" ?? undefined
                      }
                    >
                      <TrendingUp size={20} style={{ marginRight: ".25rem" }} />
                      Overview
                    </Option>
                  </BasicLink>
                  <BasicLink to="/tokens">
                    <Option
                      activeText={
                        (history.location.pathname.split("/")[1] === "tokens" ||
                          history.location.pathname.split("/")[1] ===
                            "token") ??
                        undefined
                      }
                    >
                      <Disc size={20} style={{ marginRight: ".25rem" }} />
                      Tokens
                    </Option>
                  </BasicLink>
                  <BasicLink to="/pairs">
                    <Option
                      activeText={
                        (history.location.pathname.split("/")[1] === "pairs" ||
                          history.location.pathname.split("/")[1] === "pair") ??
                        undefined
                      }
                    >
                      <PieChart size={20} style={{ marginRight: ".25rem" }} />
                      Pairs
                    </Option>
                  </BasicLink>

                  <BasicLink to="/accounts">
                    <Option
                      activeText={
                        (history.location.pathname.split("/")[1] ===
                          "accounts" ||
                          history.location.pathname.split("/")[1] ===
                            "account") ??
                        undefined
                      }
                    >
                      <List size={20} style={{ marginRight: ".25rem" }} />
                      Accounts
                    </Option>
                  </BasicLink>
                </NavLinks>
                <div style={{ marginRight: "100px" }}>
                  <Search text="Search pools or tokens" />
                </div>
              </>
            )}
          </Navbar>
          {/* <AutoColumn gap="0.5rem" style={{ marginLeft: '.75rem', marginBottom: '4rem' }}>
            <HeaderText>
              <Link href="https://swap.tomb.com/" target="_blank">
                Tomb Swap
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://tomb.com/" target="_blank">
                Tomb
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://twitter.com/tombfinance" target="_blank">
                Twitter
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://discord.gg/vANnESmVdz" target="_blank">
                Discord
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://t.me/tombfinance" target="_blank">
                Telegram
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://www.youtube.com/channel/UCYKO4BkSr4tBYjOo1ilK1SA" target="_blank">
                Youtube
              </Link>
            </HeaderText>

          </AutoColumn> */}
          {!below1180 && (
            <Polling style={{ marginLeft: ".5rem" }}>
              <PollingDot />
              <a href="/" style={{ color: "white" }}>
                <TYPE.small color={"white"}>
                  Updated {!!seconds ? seconds + "s" : "-"} ago <br />
                </TYPE.small>
              </a>
            </Polling>
          )}
        </DesktopWrapper>
      ) : (
        <MobileWrapper>
          <Title />
        </MobileWrapper>
      )}
    </Wrapper>
  )
}

export default withRouter(SideNav)
