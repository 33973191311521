import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useDarkModeManager } from '../../contexts/LocalStorage'

const pulse = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`

const Wrapper = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.fill && !props.height
      ? css`
          height: 100vh;
          background-color: ${({ theme }) => theme.bg6};
        `
      : css`
          height: 180px;
          background-color: ${({ theme }) => theme.bg6};
        
        `}
`

const AnimatedImg = styled.div`
  animation: ${pulse} 800ms linear infinite;
  & > * {
    /* width: 72px; */
  }
`

const Logo = styled.div`
    font-family: 'Amarante';
    font-size: 1.875rem;
    line-height: 2.25rem;
    text-shadow: 0 -4px 12px rgb(255 229 171 / 35%), 0 4px 4px rgb(0 0 0 / 25%);
    color: #fff2d1;
    text-align: center;
`

const LocalLoader = ({ fill }) => {
  const [darkMode] = useDarkModeManager()

  return (
    <Wrapper fill={fill}>
      <AnimatedImg>
        {/* <img src={require(darkMode ? '../../assets/logo_white.svg' : '../../assets/logo.svg')} alt="loading-icon" /> */}
        <Logo>Tomb</Logo>
      </AnimatedImg>
    </Wrapper>
  )
}

export default LocalLoader
