import "feather-icons"
import React, { useState } from "react"
import { Menu, X } from "react-feather"
import { useHistory } from "react-router-dom"
import { useMedia } from "react-use"
import { Flex } from "rebass"
import styled from "styled-components"
import { StyledIcon } from ".."
import Link, { BasicLink } from "../Link"
import { RowFixed } from "../Row"

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
`

const UniIcon = styled(Link)`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  display: flex;
`

const Option = styled.div`
  background-color: ${({ activeText }) =>
    activeText ? `rgba(17, 24, 39, 1)` : `unset`};
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  font-size: 1rem;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.9)};
  color: #fff2d1;
  display: flex;
  margin-left: 12px;
  margin-top: 5px;
  :hover {
    opacity: 1;
    background-color: rgba(55, 65, 81, 1);
  }
`

const Logo = styled.div`
  font-family: "Amarante";
  font-size: 1.675rem;
  line-height: 2.25rem;
  text-shadow: 0 -4px 12px rgb(255 229 171 / 35%), 0 4px 4px rgb(0 0 0 / 25%);
  color: #fff2d1;
`

const Logo2 = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.25rem;
  text-shadow: 0 -4px 12px rgb(255 229 171 / 35%), 0 4px 4px rgb(0 0 0 / 25%);
  color: #fff2d1;
  margin-left: 20px;
  align-self: center;
`

const IconContainer = styled.div`
  margin-right: 10px;
  border-radius: 0.375rem;
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  :hover {
    background-color: rgba(55, 65, 81, 1);
  }
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
`

export default function Title() {
  const history = useHistory()
  const below1080 = useMedia("(max-width: 1080px)")
  const below800 = useMedia("(max-width: 800px)")

  const [menuOpen, setMenuOpen] = useState(false)

  const menuClicked = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <TitleWrapper style={{ width: below1080 ? "100%" : "" }}>
      <Flex
        alignItems="center"
        style={{
          justifyContent: "space-between",
          width: below1080 ? "100%" : "",
        }}
      >
        <RowFixed>
          {/* <UniIcon id="link" onClick={() => history.push('/')}>
            <img width={'24px'} src={Logo} alt="logo" />
          </UniIcon>
          {!below1080 && (
            <img width={'84px'} style={{ marginLeft: '8px', marginTop: '0px' }} src={Wordmark} alt="logo" />
          )} */}
          {below800 ? (
            <UniIcon id="link" onClick={() => history.push("/")}>
              <Logo>Tomb</Logo>
              <Logo2>Swap Analytics</Logo2>
            </UniIcon>
          ) : (
            <UniIcon id="link" onClick={() => history.push("/")}>
              <Logo>Tomb</Logo>
              <Logo2>Swap Analytics</Logo2>
            </UniIcon>
          )}
        </RowFixed>
        {below1080 && (
          <RowFixed style={{ alignItems: "flex-end" }}>
            <IconContainer>
              <StyledIcon>
                {menuOpen ? (
                  <X size={24} onClick={menuClicked} />
                ) : (
                  <Menu size={24} onClick={menuClicked} />
                )}
              </StyledIcon>
            </IconContainer>
          </RowFixed>
        )}
      </Flex>
      {menuOpen && (
        <MenuContainer>
          <BasicLink to="/home" onClick={menuClicked}>
            <Option
              activeText={history.location.pathname === "/home" ?? undefined}
            >
              Overview
            </Option>
          </BasicLink>
          <BasicLink to="/tokens" onClick={menuClicked}>
            <Option
              activeText={
                (history.location.pathname.split("/")[1] === "tokens" ||
                  history.location.pathname.split("/")[1] === "token") ??
                undefined
              }
            >
              Tokens
            </Option>
          </BasicLink>
          <BasicLink to="/pairs" onClick={menuClicked}>
            <Option
              activeText={
                (history.location.pathname.split("/")[1] === "pairs" ||
                  history.location.pathname.split("/")[1] === "pair") ??
                undefined
              }
            >
              Pairs
            </Option>
          </BasicLink>
          <BasicLink to="/accounts" onClick={menuClicked}>
            <Option
              activeText={
                (history.location.pathname.split("/")[1] === "accounts" ||
                  history.location.pathname.split("/")[1] === "account") ??
                undefined
              }
            >
              Accounts
            </Option>
          </BasicLink>
        </MenuContainer>
      )}
    </TitleWrapper>
  )
}
