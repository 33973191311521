import React from 'react'
import { Flex } from 'rebass'
import styled from "styled-components";
import { ReactComponent as discord } from "../../assets/discord.f5669fb9.svg";
import { ReactComponent as github } from "../../assets/github.5bb1fe61.svg";
import { ReactComponent as telegram } from "../../assets/telegram.c4c99031.svg";
import { ReactComponent as twitter } from "../../assets/twitter.6cf85da1.svg";
import { ReactComponent as youtube } from "../../assets/youtube.55e2c943.svg";
import { useMedia } from 'react-use'


const Footer = () => {
  const below600 = useMedia('(max-width: 800px)')

  return (

    <Flex as="footer" p={24}>
      <FooterContents>

        {!below600 && <Logo>
          Tomb
        </Logo>}
        {!below600 &&
          <Copy> Copyright © Tomb 2022 </Copy>
        }
        <Links>
          <Link href="https://twitter.com/tombfinance" target="_blank"
            rel="noopener noreferrer" >
            <Twitter />
          </Link>
          <Link href="https://github.com/tombfinance" target="_blank"
            rel="noopener noreferrer" >
            <Github />
          </Link>
          <Link href="https://discord.com/invite/vANnESmVdz" target="_blank"
            rel="noopener noreferrer" >
            <Discord />
          </Link>
          <Link href="https://t.me/gameoftomb" target="_blank"
            rel="noopener noreferrer" >
            <Telegram />
          </Link>
          <Link href="https://www.youtube.com/channel/UCYKO4BkSr4tBYjOo1ilK1SA" target="_blank"
            rel="noopener noreferrer" >
            <Youtube />
          </Link>
        </Links>
      </FooterContents>
    </Flex>
  )
}


const Link = styled.a``

const Links = styled.div`
display: flex;
align-items: center;
& > *:not(:last-child) {
  margin-right: 10px;
}
`

const Copy = styled.div`text-shadow: 0 -4px 12px rgb(255 229 171 / 35%), 0 4px 4px rgb(0 0 0 / 25%);
color: #fff2d1;
font-size: .625rem;

`

const Logo = styled.div`
font-family: 'Amarante';
    font-size: 1rem;
    text-shadow: 0 -4px 12px rgb(255 229 171 / 35%), 0 4px 4px rgb(0 0 0 / 25%);
    color: #fff2d1;`

const FooterContents = styled.div`
text-align: center;
display: flex;
flex-direction: row;
margin: auto;
align-items: center;
& > *:not(:last-child) {
  margin-right: 24px;
}
`

const Discord = styled(discord)`
  width: 16px;
  cursor: pointer;
  & path {
    fill: #f0f0f0;
  }
`;

const Github = styled(github)`
  width: 16px;
  cursor: pointer;
  & path {
    fill: #f0f0f0;
  }
`;

const Telegram = styled(telegram)`
  width: 16px;
  cursor: pointer;
  & path {
    fill: #f0f0f0;
  }
`;

const Twitter = styled(twitter)`
  width: 16px;
  cursor: pointer;
  & path {
    fill: #f0f0f0;
  }
`;

const Youtube = styled(youtube)`
  width: 16px;
  height: auto;
  cursor: pointer;
  & path {
    fill: #f0f0f0;
  }
`;


export default Footer
